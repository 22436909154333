.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 600px) {
  .video-slide {
    width: 100%;
    height: 20em;
    position: relative
  }
}
@media only screen and (min-width: 600px) {
  .video-slide {
    width: 100%;
    height: 20em;
    position: relative
  }
}
@media only screen and (min-width: 768px) {
  .video-slide {
    width: 100%;
    height: 30em;
    position: relative
  }
}

@media only screen and (min-width: 992px) {
  .video-slide {
    width: 100%;
    height: 40em;
    position: relative
  }
}
@media only screen and (min-width: 1200px) {
  .video-slide {
    width: 100%;
    height: 723px;
    position: relative
  }
}

.blue-ifa{
  color: rgb(0, 146, 213);
}
.bg-blue-ifa{
  background-color: rgb(0, 146, 213);
}

.bg-blue-ifa-light{
  background-color:rgb(205, 232, 252);
}

.bg-shapes{
  /* background-image:url("./img//bg-shapes-svg/svgexport-20.svg");
  background-position: right top;
  background-repeat: no-repeat; */
  /* background-size: 100px; */
  position: relative;
  overflow: hidden;
}
.bg-shapes-red-right-top,.bg-shapes-blue-left-bottom{
  position: relative;
}

.bg-shapes-red-right-top::before{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 300px;
  right: -750px;
  z-index: -2;
  background-image: url("./img//bg-shapes-svg/shape-red.svg");
  background-repeat: no-repeat;
  transform: rotate(50deg);

}
.bg-shapes-blue-left-bottom::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 600px;
  left: -1200px;
  z-index: -2;
  background-image: url("./img//bg-shapes-svg/shape-blue.svg");
  background-repeat: no-repeat;
  transform: rotate(120deg);
}




