body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Roboto', sans-serif !important;
  background-color: #15511e !important;
  background-image: url("assets/background-ifruit.jpg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

@media only screen and (max-width: 600px) {
  body {
    font-family: 'Roboto', sans-serif !important;
    background-color: #15511e !important;
    background-image: url("assets/background-ifruit-mobile.jpg");
    background-position: center top;
    background-size: cover ;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  }
}